

import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
//import Particles from "particles.vue3";
import {createPinia} from 'pinia'
import router from './router'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
//import 'element-tiptap/lib/style.css';
//import ElementTiptapPlugin from 'element-tiptap-vue3-fixed';
//import 'element-tiptap-vue3-fixed/lib/style.css';

//import  mavonEditor  from  'mavon-editor' 
//import  'mavon-editor/dist/css/index.css' 

//import { WeToolbar, WeEditable, WeEditor } from 'wangeditor5-for-vue3'

import "./assets/global.css";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

global.Buffer = global.Buffer || require('buffer').Buffer

window._AMapSecurityConfig = {
  securityJsCode: "b8f5e23915e592d8cc348578a908f4b9",
};

//import ElementTiptapPlugin from 'element-tiptap';
// import VueQuillEditor from 'vue-quill-editor'
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'

const app = createApp(App)

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);//pinia 存储本地

app.use(ElementPlus)
//app.use(Particles)
app.use(pinia)
app.use(router)
//app.use(VueQuillEditor, /* { default global options } */)
//app.use(ElementTiptapPlugin);
//app.use(mavonEditor);
// app.use(WeToolbar)
// app.use(WeEditable)
// app.use(WeEditor)
// app.use(RouterStorage,{ //router 存储本地
//   showLog: false,
//   stayHere: true,
//   instanceName: '$history'
// });

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }


app.mount('#app')

